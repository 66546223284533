import React, { useMemo, useState } from 'react';
import { DayCard } from '../../components/DayCard';
import { ProgramFilter } from '../../components/ProgramFilter';
import { ProgramCard } from '../../components/ProgramCard';
import { ProgramSection } from '../../components/ProgramSection';
import filterIcon from '../../assets/svg/filter.svg';
import cls from 'classnames';
import { getTalksByDay } from '../../talks';

const topics = ['Cosmos', 'Ethereum', 'Filecoin', 'Keynote', 'Mina', 'Web3', 'Polkadot', 'Products'];
const days = [1, 2, 3];
const weekdays = ['Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const Program: React.FC = () => {
  const [visibleDay, setVisibleDay] = useState(1);
  const handleIntersection = (day: number) => (): void => setVisibleDay(day);

  const [checkedTopics, setCheckedTopics] = useState<string[]>([]);
  const onFilterClick = (on: string): void => {
    setCheckedTopics((state): string[] => {
      const index = state.findIndex((elms) => elms === on);
      if (index === -1) return [...state, on];
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };
  const onClearAllFilter = (): void => {
    setCheckedTopics([]);
  };

  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const toggleMobileFilter = (): void => setShowMobileFilter(!showMobileFilter);

  const talksList = useMemo(
    () => [getTalksByDay(1, checkedTopics), getTalksByDay(2, checkedTopics), getTalksByDay(3, checkedTopics)],
    [checkedTopics],
  );

  return (
    <div className="program-layout">
      <h2 id="program">
        Event Program
        <div className={cls('filter-btn', { 'active-filter-btn': showMobileFilter })} onClick={toggleMobileFilter}>
          <img src={filterIcon} />
        </div>
      </h2>
      <div className="program-container">
        <div className="program-sidebar">
          <div className="program-sidebar-container">
            <ProgramFilter
              onClick={onFilterClick}
              onClearAll={onClearAllFilter}
              topics={topics}
              checked={checkedTopics}
              showFilter={showMobileFilter}
            />
            <div className="day-card-container">
              {days.map((day) => (
                <DayCard key={day} day={day} active={visibleDay === day} />
              ))}
            </div>
            <ProgramFilter
              onClick={onFilterClick}
              onClearAll={onClearAllFilter}
              topics={topics}
              checked={checkedTopics}
              hideOnMobile
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          {talksList.map((talks, index) => (
            <ProgramSection
              key={index}
              day={index + 1}
              weekday={weekdays[index]}
              onIntersection={handleIntersection(index + 1)}
            >
              {talks.map((talk) => (
                <ProgramCard key={talk.talkTitle + talk.talkDay + talk.talkTime} {...talk} />
              ))}
            </ProgramSection>
          ))}
        </div>
      </div>
    </div>
  );
};
