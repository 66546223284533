import brandonKase from './assets/headshots/brandon-kase.jpeg';
import albertoViera from './assets/headshots/alberto-viera.png';
import alexStokes from './assets/headshots/alex-stokes.jpeg';
import alexMueller from './assets/headshots/alex-mueller.png';
import danForbes from './assets/headshots/dan-forbes.jpeg';
import ethanBuchman from './assets/headshots/ethan-buchman.jpeg';
import evanShapiro from './assets/headshots/evan-shapiro.jpeg';
import jeroenOfferjins from './assets/headshots/jeroen-offerjins.jpeg';
import jonathanVictor from './assets/headshots/jonathan-victor.png';
import josefWidder from './assets/headshots/josef-widder.jpeg';
import josephSpadavecchia from './assets/headshots/joseph-spadavecchia.png';
import juanBenet from './assets/headshots/juan-benet.png';
import leonDo from './assets/headshots/leon-do.jpeg';
import scottMore from './assets/headshots/scott-more.jpeg';
import sotaWatanabe from './assets/headshots/sota-watanabe.jpeg';
import timBeiko from './assets/headshots/tim-beiko.jpeg';
import vukasinVukoje from './assets/headshots/vukasin-vukoje.png';
import poojaShah from './assets/headshots/pooja.png';
import robHab from './assets/headshots/rob-h.png';
import dappLion from './assets/headshots/dapplion.png';
import colinSchwarz from './assets/headshots/colin.png';
import elizabethBinks from './assets/headshots/elizabeth.png';
import redwanMeslem from './assets/headshots/redwan.png';
import kateElBizri from './assets/headshots/kate-el-bizri.png';
import cindyChau from './assets/headshots/cindy.jpg';
import philLucsok from './assets/headshots/lucsok.png';
import andrejRakic from './assets/headshots/rakic.png';
import aidanHyman from './assets/headshots/aidan.png';
import tahaEsmael from './assets/headshots/taha.png';
import kaitlinBeegle from './assets/headshots/kaitlin.png';
import willemOlding from './assets/headshots/willem-olding.png';
import matthiasSeitz from './assets/headshots/matthias-seitz.png';
import josephLubin from './assets/headshots/joseph-lubin.jpeg';
import praneethSrikanti from './assets/headshots/praneeth.png';
import lightclients from './assets/headshots/lightclients.jpeg';
import mikeConnelly from './assets/headshots/mike-connelly.png';
import hankKorth from './assets/headshots/hank.png';
import davidHawig from './assets/headshots/david-hawig.jpeg';
import marcinGorny from './assets/headshots/marcin-gorny.jpeg';
import christineYip from './assets/headshots/christine.jpeg';
import leeRaj from './assets/headshots/lee-raj.png';
import viktorLiu from './assets/headshots/viktor-liu.jpeg';
import grahamTonkin from './assets/headshots/graham.jpeg';
import lernaJabourian from './assets/headshots/lerna.png';
import junaidTaj from './assets/headshots/junaid.png';

export interface Speaker {
  id: number;
  name: string;
  surname: string;
  companyName: string;
  headshotUrl: string;
}

const speakers: Speaker[] = [
  {
    id: 0,
    name: 'Brandon',
    surname: 'Kase',
    companyName: 'Mina',
    headshotUrl: brandonKase,
  },
  {
    id: 1,
    name: 'Evan',
    surname: 'Shapiro',
    companyName: 'Mina',
    headshotUrl: evanShapiro,
  },
  {
    id: 2,
    name: 'Josef',
    surname: 'Widder',
    companyName: 'Informal Systems',
    headshotUrl: josefWidder,
  },
  {
    id: 3,
    name: 'Vukašin',
    surname: 'Vukoje',
    companyName: 'Protocol Labs',
    headshotUrl: vukasinVukoje,
  },
  {
    id: 4,
    name: 'Scott',
    surname: 'More',
    companyName: 'Gitcoin',
    headshotUrl: scottMore,
  },
  {
    id: 5,
    name: 'Tim',
    surname: 'Beiko',
    companyName: 'Ethereum Foundation',
    headshotUrl: timBeiko,
  },
  {
    id: 6,
    name: 'Leon',
    surname: 'Do',
    companyName: 'ChainSafe',
    headshotUrl: leonDo,
  },
  {
    id: 7,
    name: 'Dan',
    surname: 'Forbes',
    companyName: 'ChainSafe',
    headshotUrl: danForbes,
  },
  {
    id: 8,
    name: 'Jeroen',
    surname: 'Offerjins',
    companyName: 'Centrifuge',
    headshotUrl: jeroenOfferjins,
  },
  {
    id: 9,
    name: 'Sota',
    surname: 'Watanabe',
    companyName: 'Astar',
    headshotUrl: sotaWatanabe,
  },
  {
    id: 10,
    name: 'Ethan',
    surname: 'Buchman',
    companyName: 'Informal Systems',
    headshotUrl: ethanBuchman,
  },
  {
    id: 11,
    name: 'Juan',
    surname: 'Benet',
    companyName: 'Protocol Labs',
    headshotUrl: juanBenet,
  },
  {
    id: 12,
    name: 'Alex',
    surname: 'Stokes',
    companyName: 'Ethereum Foundation',
    headshotUrl: alexStokes,
  },
  {
    id: 13,
    name: 'Jonathan',
    surname: 'Victor',
    companyName: 'Protocol Labs',
    headshotUrl: jonathanVictor,
  },
  {
    id: 14,
    name: 'Joseph',
    surname: 'Spadavecchia',
    companyName: 'Mina',
    headshotUrl: josephSpadavecchia,
  },
  {
    id: 15,
    name: 'Alberto',
    surname: 'Viera',
    companyName: 'PureStake',
    headshotUrl: albertoViera,
  },
  {
    id: 16,
    name: 'Alex',
    surname: 'Mueller',
    companyName: 'ChainSafe',
    headshotUrl: alexMueller,
  },
  {
    id: 17,
    name: 'Taha',
    surname: 'Esmael',
    companyName: 'ChainSafe',
    headshotUrl: tahaEsmael,
  },
  {
    id: 18,
    name: 'Aidan',
    surname: 'Hyman',
    companyName: 'ChainSafe',
    headshotUrl: aidanHyman,
  },
  {
    id: 19,
    name: 'Dapplion',
    surname: 'Lion',
    companyName: 'ChainSafe',
    headshotUrl: dappLion,
  },
  {
    id: 20,
    name: 'Mike',
    surname: 'Connelly',
    companyName: 'ChainSafe',
    headshotUrl: mikeConnelly,
  },
  {
    id: 21,
    name: 'Andrej',
    surname: 'Rakic',
    companyName: 'ChainLink',
    headshotUrl: andrejRakic,
  },
  {
    id: 22,
    name: 'Colin',
    surname: 'Schwarz',
    companyName: 'ChainSafe',
    headshotUrl: colinSchwarz,
  },
  {
    id: 23,
    name: 'Phil',
    surname: 'Lucsok',
    companyName: 'Parity',
    headshotUrl: philLucsok,
  },
  {
    id: 24,
    name: 'Pooja',
    surname: 'Shah',
    companyName: 'Protocol Labs',
    headshotUrl: poojaShah,
  },
  {
    id: 25,
    name: 'Robert',
    surname: 'Habermeier',
    companyName: 'Parity',
    headshotUrl: robHab,
  },
  {
    id: 26,
    name: 'Elizabeth',
    surname: 'Binks',
    companyName: 'ChainSafe',
    headshotUrl: elizabethBinks,
  },
  {
    id: 27,
    name: 'Redwan',
    surname: 'Meslem',
    companyName: 'ChainSafe',
    headshotUrl: redwanMeslem,
  },
  {
    id: 28,
    name: 'Kaitlin',
    surname: 'Beegle',
    companyName: 'Filecoin Foundation',
    headshotUrl: kaitlinBeegle,
  },
  {
    id: 29,
    name: 'Willem',
    surname: 'Olding',
    companyName: 'ChainSafe',
    headshotUrl: willemOlding,
  },
  {
    id: 30,
    name: 'Matthias',
    surname: 'Seitz',
    companyName: 'ChainSafe',
    headshotUrl: matthiasSeitz,
  },
  {
    id: 31,
    name: 'Joseph',
    surname: 'Lubin',
    companyName: 'Consensys',
    headshotUrl: josephLubin,
  },
  {
    id: 32,
    name: 'lightclients',
    surname: '',
    companyName: 'Consensys',
    headshotUrl: lightclients,
  },
  {
    id: 33,
    name: 'Cindy',
    surname: 'Chau',
    companyName: 'ChainSafe',
    headshotUrl: cindyChau,
  },
  {
    id: 34,
    name: 'Kate',
    surname: 'El-Bizri',
    companyName: 'Mina Foundation',
    headshotUrl: kateElBizri,
  },
  {
    id: 35,
    name: 'Praneeth',
    surname: 'Srikanti',
    companyName: 'Ethereal Ventures',
    headshotUrl: praneethSrikanti,
  },
  {
    id: 36,
    name: 'Lee',
    surname: 'Raj',
    companyName: 'ChainSafe',
    headshotUrl: leeRaj,
  },
  {
    id: 37,
    name: 'Marcin',
    surname: 'Gorny',
    companyName: 'Web3 Foundation',
    headshotUrl: marcinGorny,
  },
  {
    id: 38,
    name: 'David',
    surname: 'Hawig',
    companyName: 'Web3 Foundation',
    headshotUrl: davidHawig,
  },
  {
    id: 39,
    name: 'Hank',
    surname: 'Korth',
    companyName: 'Lehigh University',
    headshotUrl: hankKorth,
  },
  {
    id: 40,
    name: 'Christine',
    surname: 'Yip',
    companyName: 'Mina',
    headshotUrl: christineYip,
  },
  {
    id: 41,
    name: 'Viktor',
    surname: 'Liu',
    companyName: 'StakerDAO',
    headshotUrl: viktorLiu,
  },
  {
    id: 42,
    name: 'Graham',
    surname: 'Tonkin',
    companyName: 'Stateless',
    headshotUrl: grahamTonkin,
  },
  {
    id: 43,
    name: 'Lerna',
    surname: 'Jabourian',
    companyName: 'ChainSafe',
    headshotUrl: lernaJabourian,
  },
  {
    id: 44,
    name: 'Junaid',
    surname: 'Taj',
    companyName: 'ChainSafe',
    headshotUrl: junaidTaj,
  },
];

export const getSortedSpeakersByName = (): Speaker[] => speakers.sort((a, b) => a.surname.localeCompare(b.surname));

export const getSpeakerById = (id: number): Speaker | undefined => speakers.find((speaker) => speaker.id === id);
