import { getSpeakerById, Speaker } from './speakers';

interface Talk {
  talkDay: number;
  talkOrder: number;
  talkTime: string;
  talkTitle: string;
  ecosystemTag: string;
  speakers: number[]; // id from speakers.ts;
}

const talks: Talk[] = [
  // DAY 1
  {
    talkDay: 1,
    talkOrder: 900,
    talkTime: '9:00-9:30am',
    talkTitle: 'Welcome to CSCON1',
    ecosystemTag: 'Keynote',
    speakers: [18],
  },
  {
    talkDay: 1,
    talkOrder: 930,
    talkTime: '9:30-10:00am',
    talkTitle: 'Product in Web3 - Same, but different?',
    ecosystemTag: 'Products',
    speakers: [16],
  },
  {
    talkDay: 1,
    talkOrder: 1000,
    talkTime: '10:00-10:30am',
    talkTitle: 'Mina Subsystem Pathing and Deep Dive into Ouroboros Samasika Consensus',
    ecosystemTag: 'Mina',
    speakers: [0, 14],
  },
  {
    talkDay: 1,
    talkOrder: 1030,
    talkTime: '10:30-11:00am',
    talkTitle: 'Decentralising the Datacenter',
    ecosystemTag: 'Filecoin',
    speakers: [3],
  },
  {
    talkDay: 1,
    talkOrder: 1100,
    talkTime: '11:00-11:30am',
    talkTitle: 'Moonbeam: Bootstrapping an Interoperable and Ethereum Compatible Ecosystem',
    ecosystemTag: 'Polkadot',
    speakers: [15],
  },
  {
    talkDay: 1,
    talkOrder: 1130,
    talkTime: '11:30-12:00pm',
    talkTitle: 'Web3.js Makeover',
    ecosystemTag: 'Ethereum',
    speakers: [20, 44],
  },
  {
    talkDay: 1,
    talkOrder: 1230,
    talkTime: '12:30-1:00pm',
    talkTitle: 'The Ethereum Roadmap: Moving to PoS',
    ecosystemTag: 'Ethereum',
    speakers: [5],
  },
  {
    talkDay: 1,
    talkOrder: 1230,
    talkTime: '1:00-1:30pm',
    talkTitle: 'Mina Rust',
    ecosystemTag: 'Mina',
    speakers: [43],
  },
  {
    talkDay: 1,
    talkOrder: 1330,
    talkTime: '1:30-2:00pm',
    talkTitle: 'Gossamer: the Road to Polkadot Mainnet',
    ecosystemTag: 'Polkadot',
    speakers: [26],
  },
  {
    talkDay: 1,
    talkOrder: 1400,
    talkTime: '2:00-2:30pm',
    talkTitle: 'A Path to a Private & Verified Web3.0 with Mina',
    ecosystemTag: 'Keynote',
    speakers: [1],
  },
  {
    talkDay: 1,
    talkOrder: 1430,
    talkTime: '2:30-3:00pm',
    talkTitle: 'Fairer Airdrops - An Investigation',
    ecosystemTag: 'Web3',
    speakers: [29],
  },
  {
    talkDay: 1,
    talkOrder: 1500,
    talkTime: '3:00-3:30pm',
    talkTitle: 'Introducing the Polkadot Index Network Token (PINT)',
    ecosystemTag: 'Polkadot',
    speakers: [41, 42],
  },
  {
    talkDay: 1,
    talkOrder: 1530,
    talkTime: '3:30-4:00pm',
    talkTitle: 'Blockchain in Universities: What Should They Teach and to Whom Should They Teach it?',
    ecosystemTag: 'Web3',
    speakers: [39],
  },
  // DAY 2
  {
    talkDay: 2,
    talkOrder: 900,
    talkTime: '9:00-9:30am',
    // eslint-disable-next-line prettier/prettier
    talkTitle: 'Why it\'s Important to Run Your Own Nodes',
    ecosystemTag: 'Polkadot',
    speakers: [37],
  },
  {
    talkDay: 2,
    talkOrder: 930,
    talkTime: '9:30-10:00am',
    talkTitle: '',
    ecosystemTag: 'Polkadot',
    speakers: [9],
  },
  {
    talkDay: 2,
    talkOrder: 1000,
    talkTime: '10:00-10:30am',
    talkTitle: 'Ethereum is Solar Punk: Reimagining Public Goods',
    ecosystemTag: 'Ethereum',
    speakers: [4],
  },
  {
    talkDay: 2,
    talkOrder: 1030,
    talkTime: '10:30-11:00am',
    talkTitle: 'Web3 Grants and the Future of Public Goods Funding',
    ecosystemTag: 'Polkadot',
    speakers: [38],
  },
  {
    talkDay: 2,
    talkOrder: 1100,
    talkTime: '11:00-11:30am',
    talkTitle: '',
    ecosystemTag: 'Polkadot',
    speakers: [23],
  },
  {
    talkDay: 2,
    talkOrder: 1130,
    talkTime: '11:30-12:00pm',
    talkTitle: '',
    ecosystemTag: 'Ethereum',
    speakers: [35],
  },
  {
    talkDay: 2,
    talkOrder: 1230,
    talkTime: '12:30-1:00pm',
    talkTitle: 'Bridging Web2 to Web3: Scaling to Petabyte Scale Storage',
    ecosystemTag: 'Filecoin',
    speakers: [13],
  },
  {
    talkDay: 2,
    talkOrder: 1300,
    talkTime: '1:00-1:30pm',
    talkTitle: 'Cross Chain Messaging, XCM, a use case with the PINT index',
    ecosystemTag: 'Polkadot',
    speakers: [27, 30],
  },
  {
    talkDay: 2,
    talkOrder: 1330,
    talkTime: '1:30-2:00pm',
    talkTitle: 'Crowdsourcing the Codebase: An Intro to Protocol Governance in Web 3.0',
    ecosystemTag: 'Filecoin',
    speakers: [28],
  },
  {
    talkDay: 2,
    talkOrder: 1400,
    talkTime: '2:00-2:30pm',
    talkTitle: 'Building Communities in Crypto',
    ecosystemTag: 'Mina',
    speakers: [40],
  },
  {
    talkDay: 2,
    talkOrder: 1430,
    talkTime: '2:30-3:00pm',
    talkTitle: 'Game Engines in Web3',
    ecosystemTag: 'Products',
    speakers: [6],
  },
  {
    talkDay: 2,
    talkOrder: 1500,
    talkTime: '3:00-3:30pm',
    talkTitle: '',
    ecosystemTag: 'Keynote',
    speakers: [11],
  },
  {
    talkDay: 2,
    talkOrder: 1530,
    talkTime: '3:30-4:00pm',
    talkTitle: 'Accessible Distributed Storage: How ChainSafe is Improving the Foundations of the Internet',
    ecosystemTag: 'Products',
    speakers: [22],
  },
  // DAY 3
  {
    talkDay: 3,
    talkOrder: 900,
    talkTime: '9:00-9:30am',
    talkTitle: 'ChainBridge',
    ecosystemTag: 'Products',
    speakers: [17],
  },
  {
    talkDay: 3,
    talkOrder: 930,
    talkTime: '9:30-10:00am',
    talkTitle: 'Building Bridges - Exploring the Design Space for Connecting Blockchains',
    ecosystemTag: 'Cosmos',
    speakers: [2],
  },
  {
    talkDay: 3,
    talkOrder: 1000,
    talkTime: '10:00-10:30am',
    talkTitle: '',
    ecosystemTag: 'Filecoin',
    speakers: [36],
  },
  {
    talkDay: 3,
    talkOrder: 1030,
    talkTime: '10:30-11:00am',
    talkTitle: '',
    ecosystemTag: 'Ethereum',
    speakers: [12],
  },
  {
    talkDay: 3,
    talkOrder: 1100,
    talkTime: '11:00-11:30am',
    talkTitle: '',
    ecosystemTag: 'Cosmos',
    speakers: [10],
  },
  {
    talkDay: 3,
    talkOrder: 1130,
    talkTime: '11:30-12:00pm',
    talkTitle: 'Hybrid Smart Contracts and Decentralized Oracle Networks',
    ecosystemTag: 'Web3',
    speakers: [21],
  },
  {
    talkDay: 3,
    talkOrder: 1230,
    talkTime: '12:30-1:00pm',
    talkTitle: 'Realizing the Light-Client Dream in Ethereum',
    ecosystemTag: 'Ethereum',
    speakers: [19],
  },
  {
    talkDay: 3,
    talkOrder: 1300,
    talkTime: '1:00-1:30pm',
    talkTitle: '',
    ecosystemTag: 'Filecoin',
    speakers: [24],
  },
  {
    talkDay: 3,
    talkOrder: 1330,
    talkTime: '1:30-2:00pm',
    talkTitle: 'Real World NFTs',
    ecosystemTag: 'Polkadot',
    speakers: [8],
  },
  {
    talkDay: 3,
    talkOrder: 1400,
    talkTime: '2:00-2:30pm',
    talkTitle: 'Ethereum Accounts of the Future',
    ecosystemTag: 'Ethereum',
    speakers: [32],
  },
  {
    talkDay: 3,
    talkOrder: 1430,
    talkTime: '2:30-3:00pm',
    talkTitle: '',
    ecosystemTag: 'Keynote',
    speakers: [25],
  },
  {
    talkDay: 3,
    talkOrder: 1500,
    talkTime: '3:00-3:30pm',
    talkTitle: 'Fireside Chat with Aidan Hyman',
    ecosystemTag: 'Keynote',
    speakers: [31, 18],
  },
  {
    talkDay: 3,
    talkOrder: 1530,
    talkTime: '3:30-4:00pm',
    talkTitle: 'Branding in Web3',
    ecosystemTag: 'Web3',
    speakers: [33, 34],
  },
];

export interface ExtendedTalk extends Omit<Talk, 'speakers'> {
  speakers: Speaker[];
}

export const getTalks = (): ExtendedTalk[] =>
  talks.map((talk) => ({
    ...talk,
    speakers: talk.speakers.map(getSpeakerById).filter((speaker) => speaker !== undefined) as Speaker[],
  }));

export const getTalksByDay = (day: number, topics: string[]): ExtendedTalk[] =>
  getTalks()
    .filter(({ talkDay, ecosystemTag }) => talkDay === day && (topics.length ? topics.includes(ecosystemTag) : true))
    .sort((a, b) => a.talkOrder - b.talkOrder);
