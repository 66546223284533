import React, { FC, useState } from 'react';
import cls from 'classnames';
import { MobileHeaderWrapper } from '../../components/MobileHeaderWrapper';

export const Header: FC = () => {
  const [opened, setOpened] = useState(false);

  return (
    <header className={cls({ opened })}>
      <MobileHeaderWrapper onBurgerClick={(): void => setOpened(true)} />
      <div className="cover" onClick={(): void => setOpened(false)}>
        <MobileHeaderWrapper onBurgerClick={(): void => setOpened(false)} opened />
        <div className="cover-content">
          <p>Dec 1-3 ‘21 | Online | Free</p>
          <ul>
            <li className="click-effect">
              <a href="#program">event program</a>
            </li>
            <li className="click-effect">
              <a href="#speakers">speakers</a>
            </li>
            <li className="click-effect">
              <a href="https://live.remo.co/e/cscon1/register" target="_blank" rel="noopener noreferrer">
                join event on remo
              </a>
            </li>
            <li className="click-effect">
              <a
                href="https://live.remo.co/youtube-live/cscon1/619cc172ea87aa000a699905"
                target="_blank"
                rel="noopener noreferrer"
              >
                watch on YouTube
              </a>
            </li>
          </ul>
        </div>
      </div>
      <aside>
        <h1>CSCON1</h1>
        <p>Dec 1-3 ‘21 | Online | Free</p>
        <div className="separator" />
        <ul className="menu">
          <li className="click-effect">
            <a href="#program">event program</a>
          </li>
          <li className="click-effect">
            <a href="#speakers">speakers</a>
          </li>
          <li className="click-effect">
            <a href="https://live.remo.co/e/cscon1/register" target="_blank" rel="noopener noreferrer">
              join event on remo
            </a>
          </li>
          <li className="click-effect">
            <a
              href="https://live.remo.co/youtube-live/cscon1/619cc172ea87aa000a699905"
              target="_blank"
              rel="noopener noreferrer"
            >
              watch livestream
            </a>
          </li>
        </ul>
        <ul className="leaders" ul-title="With leaders from:">
          <li>Ethereum</li>
          <li>Polkadot</li>
          <li>Filecoin</li>
          <li>Cosmos</li>
          <li>Mina</li>
        </ul>
        <p className="leaders-compact">
          With leaders from: <br />
          Ethereum, Polkadot, Filecoin, Cosmos & Mina
        </p>
      </aside>
    </header>
  );
};
