import React from 'react';
import { getSortedSpeakersByName, Speaker } from '../../speakers';
import { SpeakerCard } from '../../components/SpeakerCard';
import blank from '../../assets/headshots/blank.png';

const moreTBD: Speaker = {
  id: 9999,
  name: 'More',
  surname: 'speakers',
  companyName: 'to be announced!',
  headshotUrl: blank,
};
export const Speakers: React.FC = () => (
  <div className="speakers-container">
    <h2 id="speakers">Speakers</h2>
    <h3>
      ChainSafe is pleased to bring you this incredible and diverse lineup of blockchain leaders. Deep dive into
      sessions on blockchain research, protocol engineering and much more.
    </h3>
    <div className="speakers-list">
      {getSortedSpeakersByName().map((speaker) => (
        <SpeakerCard key={speaker.name + speaker.surname + speaker.companyName} {...speaker} />
      ))}
      <SpeakerCard {...moreTBD} />
    </div>
  </div>
);
