import React from 'react';
import cls from 'classnames';

interface Props {
  onClearAll: () => void;
  onClick: (option: string) => void;
  checked: string[];
  topics: string[];
  hideOnMobile?: boolean;
  showFilter?: boolean;
}

export const ProgramFilter: React.FC<Props> = ({ onClearAll, onClick, checked, topics, hideOnMobile, showFilter }) => {
  const handleClick = (topic: string) => (): void => onClick(topic);
  return (
    <div className={cls('program-filter-container', { 'hide-on-mobile': hideOnMobile, 'show-filter': showFilter })}>
      <div className="program-filter-header">
        <div className="label">Filter by:</div>
        <div className="clear-btn" onClick={onClearAll}>
          Clear all
        </div>
      </div>
      <h4 className="filter-header">Topic</h4>
      <div>
        {topics.map((topic) => (
          <label className="checkbox-container" key={topic}>
            {topic}
            <input type="checkbox" checked={checked.includes(topic)} onChange={handleClick(topic)} />
          </label>
        ))}
      </div>
    </div>
  );
};
