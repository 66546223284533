import React from 'react';
import { Speaker } from '../../speakers';

export const SpeakerCard: React.FC<Speaker> = ({ name, surname, companyName, headshotUrl }) => (
  <div className="speaker-card">
    <img src={headshotUrl} alt={`${name} portrait`} />
    <p>
      {name} {surname}
    </p>
    <p>{companyName}</p>
  </div>
);
