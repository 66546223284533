import React, { FC } from 'react';
import { Header } from '../../containers';
import { HorizontalLine } from '../../components/HorizontalLine';
import { Hero } from '../../components/Hero';
import { Speakers } from '../../containers/Speakers';
import { Program } from '../../containers/Program';

export const Landing: FC = () => {
  //  Wednesday, 1st December 2021 15:00:00 GMT (10am EST)
  // const countDownDate = new Date(2021, 11, 1, 15, 0, 0);
  return (
    <div className="landing">
      <Header />
      <main>
        <HorizontalLine />
        {/*<CountdownTimer countdownDate={countDownDate} />*/}
        <Hero />
        <HorizontalLine />
        <Program />
        <Speakers />
      </main>
    </div>
  );
};
