import React, { useEffect, useRef } from 'react';

interface Props {
  day: number;
  weekday: string;
  onIntersection: (day: number) => void;
}

const options: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.25,
};

export const ProgramSection: React.FC<Props> = ({ day, weekday, onIntersection, children }) => {
  const handleIntersection: IntersectionObserverCallback = (entries): void => {
    if (entries.length && entries[0].isIntersecting) onIntersection(day);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return (): void => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef]);

  return (
    <div ref={containerRef} className="program-section-container">
      <span className="program-section-day">DAY {day}</span>
      <h3 id={`day-${day}`} className="program-section-title">
        {weekday}, December {day}
      </h3>
      <span className="program-section-day">Times are in Eastern Standard Time (EST UTC-5)</span>
      {children}
    </div>
  );
};
