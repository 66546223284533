import React from 'react';
import ChainSageLogo from '../../assets/ChainSafe-logo.png';

export const Hero: React.FC = () => (
  <div className="hero-container">
    <h1 className="hero-text">Makers, meet each other.</h1>
    <h2 className="hero-sub-text">Mingle with makers of the multi-chain future. An event you won’t want to miss.</h2>
    <p className="details">Dec 1-3 2021 | Online | Free to join.</p>
    <div className="hosted-by">
      <img src={ChainSageLogo} alt="ChainSageLogo" />
      <a href="https://chainsafe.io/" about="ChainSafe homepage" target="_blank" rel="noopener noreferrer">
        hosted by ChainSafe
      </a>
    </div>
  </div>
);
