import React from 'react';
import { ExtendedTalk } from '../../talks';

const colors = {
  Cosmos: '#5064FB',
  Ethereum: '#6973C9',
  Filecoin: '#0090FF',
  Keynote: '#FFA940',
  Web3: '#06A77A',
  Mina: '#6404B0',
  Polkadot: '#D0016F',
  Products: '#FFFF38',
};

export const ProgramCard: React.FC<ExtendedTalk> = ({ talkTime, talkTitle, speakers, ecosystemTag }) => (
  <div className="program-card">
    <span className="time">{talkTime}</span>
    <h4 className="talk-title">{talkTitle ? talkTitle : `TBD`}</h4>
    <div className="talk-speaker">
      <div>
        {speakers.map(({ name, surname, companyName }) => (
          <div className="speaker-info" key={name + surname + companyName}>
            {name} {surname}, {companyName}
          </div>
        ))}
      </div>

      <div className="topic">
        <div className="box" style={{ backgroundColor: colors[ecosystemTag as keyof typeof colors] || '#fff' }} />
        <div className="text">{ecosystemTag}</div>
      </div>
    </div>
  </div>
);
