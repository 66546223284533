import React from 'react';

interface Props {
  day: number;
  active: boolean;
}

export const DayCard: React.FC<Props> = ({ day, active }) => (
  <div className="day-card-navigation">
    <span className="day-card-day">Day {day}</span>
    <br />
    <a href={`#day-${day}`} className={active ? 'active' : undefined}>
      December {day}
    </a>
  </div>
);
